import { Account } from 'src/features/login/api/account.types';
import { CustomerData } from 'src/types/CustomerData';
import { Contract } from '../types/Contract';

export const evaluateClaimLimitReached = (contract: Contract): boolean => {
    const annualClaimCostGbp = (contract.annualClaimCostGbx || 0) / 100;
    if (
        contract.packageDetails.annualClaimCostLimitGbp &&
        annualClaimCostGbp &&
        annualClaimCostGbp > contract.packageDetails.annualClaimCostLimitGbp - 50
    ) {
        return true;
    }

    return false;
};

export const buildCustomerData = (account: Account, contract: Contract): CustomerData => {
    return {
        packageId: contract.packageId,
        isOilPackage: contract.isOilPackage,
        includedProducts: contract.includedProducts,
        billingType: contract.billingType,
        contribution: contract.contribution || 0,
        firstName: account.firstName,
        lastName: account.lastName,
        customerFacingId: contract.contractCustomerFacingId,
        contractId: contract.id,
        sk_account_id: contract.skAccountId,
        email: account.email,
        postcode: contract.property?.postcode,
        jobs: contract.jobs,
        claimLimitReached: evaluateClaimLimitReached(contract),
        packageDetails: {
            onlyLossOfService: contract.packageDetails?.onlyLossOfService,
            heatPumpService: contract.packageDetails?.heatPumpService,
            tapsService: contract.packageDetails?.tapsService,
            showersService: contract.packageDetails?.showersService,
            requiresInsurerApproval: contract.packageDetails?.requiresInsurerApproval,
        },
        appliances: contract.appliances,
        isRenewal: contract.isRenewal,
        startDate: contract.startDate,
    };
};
