import Button from 'src/components/Button';
import style from './index.module.scss';

type Props = {
    setShowBanner: (show: boolean) => void;
    isAuthPage?: boolean;
};

const CookieBanner = ({ setShowBanner, isAuthPage }: Props) => {
    if (localStorage.cookieAccepted) return null;

    const handleClickEvent = () => {
        localStorage.setItem('cookieAccepted', 'true');
        setShowBanner(false);
    };
    return (
        <section className={`${style.container} ${isAuthPage ? style.authContainerStyle : ''}`}>
            <div className={`${style.banner} ${isAuthPage ? style.authStyle : ''}`}>
                <p className={style.bannerMessage}>
                    We use cookies to provide you with a better service. Carry on browsing if
                    you&apos;re happy with this, or find out how to manage cookies.&nbsp;
                    <a
                        href="https://www.hometree.co.uk/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                        data-variant="secondary"
                    >
                        Learn More
                    </a>
                </p>
                <Button
                    customStyle={style.bannerButton}
                    aria-label="close cookies"
                    variant="primary"
                    onClick={handleClickEvent}
                >
                    Accept &#38; continue
                </Button>
            </div>
        </section>
    );
};

export default CookieBanner;
