export function isContractWithinCoolingOffPeriod(
    contractStartDate: Date,
    isRenewal?: boolean | null,
    now = new Date()
) {
    try {
        if (isRenewal) return false;

        const dateIsoString = contractStartDate.toISOString();
        const removeTimeFromString = dateIsoString.split('T')[0];
        const startDate = new Date(removeTimeFromString);
        startDate.setDate(startDate.getDate() + 14);

        if (now < startDate) {
            return true;
        }

        return false;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
            error.message,
            JSON.stringify({
                error,
                contractStartDate,
            })
        );
        return false;
    }
}
