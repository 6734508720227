import { Contract } from 'src/features/newClaim/api/getContract.types';
import { isContractWithinCoolingOffPeriod } from 'src/utils/isContractWithinCoolingOffPeriod';
import { ArrowRight } from '../../../images';
import styles from './index.module.scss';

interface Props {
    contracts: Contract[];
    onSelect: (contractId: number) => void | undefined;
}

const ContractsList = ({ contracts, onSelect }: Props) => {
    return (
        <div className={styles.propertiesList}>
            {contracts.map((contract, index) => {
                const isContractOnCoolOff = isContractWithinCoolingOffPeriod(
                    new Date(contract.startDate),
                    contract.isRenewal
                );
                return (
                    <button
                        type="button"
                        key={contract.id}
                        onClick={() => (!isContractOnCoolOff ? onSelect(contract.id) : undefined)}
                        data-testid={`contract-select-address-button-${index}`}
                        className={`${styles.contractContainer} ${
                            isContractOnCoolOff ? `${styles.disabled}` : ''
                        }`}
                    >
                        <div>
                            {contract.property.address.line1}, {contract.property.town},{' '}
                            {contract.property.postcode}
                        </div>
                        <ArrowRight />
                        <span className={styles.coolingOff}>
                            {isContractOnCoolOff
                                ? `You are in your cooling-off period, so we will not be able to process your claim at this time. Please try again after 14 days have passed since your start date.`
                                : ''}
                        </span>
                    </button>
                );
            })}
        </div>
    );
};

export default ContractsList;
